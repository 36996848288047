import React from "react";

export const typeList = [{ value: "application", label: "Application" }];

export const boolean = [
  { value: true, label: "True" },
  { value: false, label: "False" }
];

export const returnResourceQuery = (key, index) => {
  return {
    size: 10000,
    _source: ["ID", "ACCOUNT", "REGION", "TAGGABLE", "STD_Tags"],
    query: {
      bool: {
        filter: {
          range: { last_seen: { gt: "now-24h" } }
        },
        must: [
          {
            match: { _index: index }
          },
          {
            match: { "SYSTEM_ID.keyword": key }
          }
        ]
      }
    }
  };
};

export const returnResourceIndiciesQuery = key => {
  return {
    size: 0,
    aggs: {
      groupbyindex: {
        terms: {
          field: "_index",
          size: 1000
        }
      }
    },
    query: {
      bool: {
        filter: {
          range: { last_seen: { gt: "now-24h" } }
        },
        must: [
          {
            match: { _index: "a*" }
          },
          {
            nested: {
              path: "STD_Tags",
              query: {
                bool: {
                  must: [{ match: { "STD_Tags.Value.keyword": key } }]
                }
              }
            }
          }
        ]
      }
    }
  };
};

export const returnAccountResourceQuery = (account, index) => {
  return {
    size: 10000,
    _source: ["ID", "ACCOUNT", "REGION", "TAGGABLE", "STD_Tags"],
    query: {
      bool: {
        filter: {
          range: { last_seen: { gt: "now-24h" } }
        },
        must: [
          { match: { _index: index } },
          { match: { "ACCOUNT.keyword": account } }
        ],
        must_not: [
          {
            nested: {
              path: "STD_Tags",
              query: {
                bool: {
                  must: [{ match: { "STD_Tags.Key.keyword": "nike-tagguid" } }]
                }
              }
            }
          },
          {
            nested: {
              path: "STD_Tags",
              query: {
                bool: {
                  must: [{ match: { "STD_Tags.Key.keyword": "TagGuid" } }]
                }
              }
            }
          },
          {
            nested: {
              path: "STD_Tags",
              query: {
                bool: {
                  must: [{ match: { "STD_Tags.Key.keyword": "AppDefined02" } }]
                }
              }
            }
          }
        ]
      }
    }
  };
};

export const returnAccountIndicesQuery = account => {
  return {
    size: 0,
    aggs: {
      groupbyindex: {
        terms: {
          field: "_index",
          size: 1000
        }
      }
    },
    query: {
      bool: {
        filter: {
          range: { last_seen: { gt: "now-24h" } }
        },
        must: [
          { match: { _index: "aws*" } },
          { match: { "ACCOUNT.keyword": account } }
        ],
        must_not: [
          {
            nested: {
              path: "STD_Tags",
              query: {
                bool: {
                  must: [{ match: { "STD_Tags.Key.keyword": "nike-tagguid" } }]
                }
              }
            }
          },
          {
            nested: {
              path: "STD_Tags",
              query: {
                bool: {
                  must: [{ match: { "STD_Tags.Key.keyword": "TagGuid" } }]
                }
              }
            }
          },
          {
            nested: {
              path: "STD_Tags",
              query: {
                bool: {
                  must: [{ match: { "STD_Tags.Key.keyword": "AppDefined02" } }]
                }
              }
            }
          }
        ]
      }
    }
  };
};

export const formatdata = (data, highlights = Array([])) => {
  const list_items = [];
  let key = null;
  let value = null;
  for (var item in data) {
    if (item === "STD_Tags") continue;
    key = <span style={{ fontWeight: "bold" }}>{item}:</span>;
    if (typeof data[item] === "object") {
      value = formatdata(data[item], highlights);
    } else {
      value = JSON.stringify(data[item]);
    }

    list_items.push(
      highlights.includes(item) ? (
        <li key={item} style={{ backgroundColor: "yellow" }}>
          {key}
          {value}
        </li>
      ) : (
        <li key={item}>
          {key}
          {value}
        </li>
      )
    );

    key = null;
    value = null;
  }
  return <ul>{list_items}</ul>;
};

export const returnAccountSystems = account => {
  return {
    size: 0,
    aggs: {
      associations: {
        terms: {
          field: "SYSTEM_ID.keyword",
          size: 10000
        }
      }
    },
    query: {
      bool: {
        filter: {
          range: {
            last_seen: {
              gt: "now-24h"
            }
          }
        },
        must: [
          {
            match: {
              _index: "aws*"
            }
          },
          {
            match: {
              "ACCOUNT.keyword": account
            }
          }
        ]
      }
    }
  };
};

export const returnSystemAccounts = system => {
  return {
    size: 0,
    aggs: {
      associations: {
        terms: {
          field: "ACCOUNT.keyword",
          size: 10000
        }
      }
    },
    query: {
      bool: {
        filter: {
          range: {
            last_seen: {
              gt: "now-24h"
            }
          }
        },
        must: [
          {
            match: {
              _index: "aws*"
            }
          },
          {
            match: {
              "SYSTEM_ID.keyword": system
            }
          }
        ]
      }
    }
  };
};

export const returnAzureIndicesQuery = key => {
  return {
    size: 0,
    aggs: {
      groupbyindex: {
        terms: {
          field: "_index",
          size: 1000
        }
      }
    },
    query: {
      bool: {
        filter: {
          range: { last_seen: { gt: "now-24h" } }
        },
        must: [
          {
            match: { _index: "a*" }
          }
        ],
        should: [
          {
            match: { "SYSTEM_ID.keyword": key }
          },
          {
            match: { "RESOURCE_GROUP_SYSTEM_ID.keyword": key }
          },
          {
            match: { "SUBSCRIPTION_SYSTEM_ID.keyword": key }
          }
        ],
        minimum_should_match: 1
      }
    }
  };
};

export const returnAzureResourceQuery = (key, index) => {
  return {
    size: 10000,
    _source: ["ID", "ACCOUNT", "REGION", "TAGGABLE", "STD_Tags"],
    query: {
      bool: {
        filter: {
          range: { last_seen: { gt: "now-24h" } }
        },
        must: [
          {
            match: { _index: index }
          }
        ],
        should: [
          {
            match: { "SYSTEM_ID.keyword": key }
          },
          {
            match: { "RESOURCE_GROUP_SYSTEM_ID.keyword": key }
          },
          {
            match: { "SUBSCRIPTION_SYSTEM_ID.keyword": key }
          }
        ],
        minimum_should_match: 1
      }
    }
  };
};
